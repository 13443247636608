<template>
  <div class="relative grid">
    <h3 class="font-bold">{{ title }}</h3>

    <input :id="`file-${fileName.replace(' ', '-')}`" :name="fileName" type="file" class="hidden"
      @change="handleUploadToStorage">

    <div class="relative">
      <input :id="title" placeholder="Selecione um arquivo pdf ou imagem" type="text"
        :class="[validation ? 'border-red-500 border-2 ' + fileName : fileName, inputValue ? 'pr-12' : '']"
        class="mt-2 border border-dashed w-full rounded-lg border-gray-400 px-3 py-2 text-gray-900 outline-none transition-all duration-200 cursor-pointer bg-gray-100 hover:bg-gray-300 hover:border-transparent"
        v-model="inputValue" @click="openExplorer">

      <button v-if="inputValue" @click="removeFile"
        class="absolute right-3 top-7 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 text-xl p-2 flex items-center justify-center">
        <FontAwesomeIcon :icon="closeIcon" />
      </button>
    </div>

    <span class="text-xs">Extensões aceitas: .png, .jpeg, .pdf (Limite de 1MB)</span>
  </div>
</template>

<script>
import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


export default {
  name: 'FileUpload',
  props: ["title", "fileName", "validation", "estabelecimentoCnpj", "value"],
  emits: ['changeField', 'update:value', 'removeFile'],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      inputValue: this.value || null,
      check: false,
      fileHash: "",
      closeIcon: faTimes,
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    }
  },
  methods: {
    async handleUploadToStorage(event) {
      if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];

        if (this.file.type !== "image/png" && this.file.type !== "image/jpeg" && this.file.type !== "application/pdf") {
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "O arquivo deve ser do formato PDF, PNG ou JPEG.",
            confirmButtonText: "Ok",
          });
          return;
        }

        if (this.file.size > 1024 * 1024) {
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "O arquivo deve ter no máximo 1MB.",
            confirmButtonText: "Ok",
          });
          return;
        }

        const reader = new FileReader();
        reader.onload =  () => {
          const base64Data = reader.result;
          this.triggerChangeField({
            content: base64Data,
            fileName: this.file.name
          })
        };
        reader.readAsDataURL(this.file);

        this.check = true
      }
      else {
        this.inputValue = null
        this.check = false
      }
    },

    openExplorer() {
      document.querySelector(`#file-${this.fileName.replace(' ', '-')}`).click();
    },

    triggerChangeField(data) {
      const fileData = {
        fileHash: this.fileHash,
        fileName: data.fileName,
        content: data.content
      }
      this.$emit("changeField", this.fileName, fileData);
    },

    removeFile(event) {
      event.preventDefault();
      this.inputValue = null;
      this.$emit('removeFile', this.fileName);
      this.check = false
    }
  }
};
</script>

<style>
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
