<template>
    <Card title="Dados do Responsável pelo Financeiro" v-if="registering">
        <form class="my-9 divide-y" @keyup.enter="next">
            <div class="grid grid-cols-12 mb-7 gap-5">
                <Input label="Nome Completo" :autofocus="true" name="NomeCompleto" :value="financialForm.NomeCompleto"
                    :validation="formValidation.NomeCompleto" @change="changeField"
                    class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="CPF" name="CPF" mask="###.###.###-##" :value="financialForm.CPF"
                    :validation="formValidation.CPF" @change="changeField"
                    class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="E-mail Principal" name="Email" @change="changeField" :value="financialForm.Email"
                    :validation="formValidation.Email" class="col-span-12 sm:col-span-6 lg:col-span-3" />
                <Input label="Celular" name="Celular" mask="(##) #####-####" type="phone" @change="changeField"
                    :value="financialForm.Celular" :validation="formValidation.Celular"
                    class="col-span-12 sm:col-span-6 lg:col-span-3" />
            </div>
        </form>
        <div class="flex">
            <Button @click="back" :icon="backIcon" :class="{ 'custom-buttom-disabled': loading }" :loading="loading" iconPosition="left" label="Voltar"
                class="ml-auto hover:bg-gray-300" />
            <PrimaryButton @click="next" :icon="nextIcon" iconPosition="right" :class="{ 'custom-buttom-disabled': loading }" :loading="loading" label="Prosseguir"
                class="ml-5 next" />
        </div>
    </Card>
</template>

<script>
import Card from '../../../ui/Card.vue'
import PrimaryButton from '../../../buttons/PrimaryButton'
import Button from '../../../buttons/Button'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Input from '../../../input/Input'
import utils from '@/utils'
import api from "@/services/api";

export default {
    name: 'FinancialData',
    props: ['data', 'clientId'],
    components: {
        Card,
        PrimaryButton,
        Button,
        Input
    },
    data() {
        return {
            registering: true,
            loading: false,
            nextIcon: faChevronRight,
            backIcon: faChevronLeft,
            lock: false,
            financialForm: {
                NomeCompleto: '',
                Email: '',
                Celular: '',
                CPF: '',
                Cargo: 'financeiro',
                Status: 'Ativo'
            },
            formValidation: {
            },
            date: null,
            newRegister: true,
        }
    },
    mounted() {
        if (this.data && this.data.uuid) {
            this.financialForm = this.data
            this.financialForm.Celular = utils.applyMask('Celular', this.financialForm.Celular)
            this.financialForm.CPF = utils.applyMask('CPF', this.financialForm.CPF)
            this.newRegister = false;
        }
    },
    methods: {
        changeField(index, value) {
            this.financialForm[index] = value

            utils.validate(
                index,
                this.financialForm,
                this.formValidation,
                this.clientType
            );
        },

        async next() {
            this.loading = true
            this.formValidation = {}

            Object.keys(this.financialForm).forEach(field => {
                field = field.replaceAll("Validated", "")
                if (!this.financialForm[field]) {
                    this.formValidation[field] = 'Este campo é obrigatório.'
                }
                utils.validate(
                    field,
                    this.financialForm,
                    this.formValidation,
                    this.clientType
                );
            })

            if (Object.keys(this.formValidation).length > 1) {
                this.loading = false
                return false
            }

            if(this.newRegister) await this.createResponsible();
            else await this.updateResponsible();

            this.$emit('next', this.financialForm)
            this.loading = false
        },

        back() {
            this.$emit('back', this.financialForm)
        },

        async createResponsible() {
          this.loading = true
          const response = await api.post(`/responsible`, {
              ...utils.formatResponsibleToDatabase(this.financialForm),
              role: 'financeiro'
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            }).catch( () => {     
              this.$swal({
                title: "Erro!",
                icon: "error",
                allowOutsideClick: true,
                text: "Houve um problema ao registrar responsável financeiro, tente novamente.",
                confirmButtonText: "Ok",
              })
              this.loading = false
            })
            this.loading = false
            const { responsavel } = utils.formatResponsibleToForm([response.data]);
            this.financialForm = responsavel
        },

        async updateResponsible() {
          this.loading = true
          const response = await api.patch(`/responsible/${this.financialForm.uuid}`, {
              ...utils.formatResponsibleToDatabase(this.financialForm),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            }).catch( () => {     
              this.$swal({
                title: "Erro!",
                icon: "error",
                allowOutsideClick: true,
                text: "Houve um problema ao atualizar responsável financeiro, tente novamente.",
                confirmButtonText: "Ok",
              })
              this.loading = false
            })
            const { responsavel } = utils.formatResponsibleToForm([response.data]);
            this.financialForm = responsavel
            this.loading = false
        },
    }
}
</script>