<style>
.custom-background {
  background: #5854F1 !important;
  background-size: cover;
  min-height: 275px;
  z-index: 0;
}

.custom-background-inverse {
  bottom: 0;
  background: #5854F1 !important;
  background-size: cover;
  min-height: 275px;
  z-index: 0;
}

.form {
  z-index: 1;
}

.swal2-styled.swal2-confirm {
  background: #5854F1 !important;
  background-image: linear-gradient(54deg, #5854F1, #3535C4, #5854F1, #3535C4) !important;
  text-align: center !important;
  background-size: 300% 100% !important;
  -moz-transition: all .2s ease-in-out !important;
  -o-transition: all .2s ease-in-out !important;
  -webkit-transition: all .2s ease-in-out !important;
  transition: all .2s ease-in-out !important;
}

.swal2-styled.swal2-confirm:hover {
  opacity: 0.75 !important;
}

.swal2-styled.swal2-confirm:focus {
  outline: none !important;
  background: #3535C4 !important;
}

#recovery {
  top: -99.5%;
  transition: 1s;
  z-index: 0;
}

.recovery-active {
  transform: translateY(100%);
}

#tecnoaccount {
  top: -99.5%;
  transition: 1s;
  z-index: 1;
}

.tecnoaccount-active {
  transform: translateY(100%);
}

#auth-form {
  bottom: -99.5%;
  transition: 1s;
  width: inherit;
  z-index: 2;
}

.auth-form-active {
  transform: translateY(-100%);
}
</style>

<template>
  <div class="overflow-hidden w-full h-screen">
    <div id="auth-form" class="absolute w-full h-screen flex justify-center auth"
      :class="page == 'authenticating' ? 'auth-form-active' : ''">
      <div class="custom-background absolute w-full h-1/2" />
      <div class="m-auto form">
        <img src="/logo-white.png" width="200" height="50" class="mx-auto mt-2" />
        <Card class="m-4"
          :title="clientType == 'company' ? 'Dados do Estabelecimento' : 'Dados da Software House'"
          titlePosition="center">
          <div>
            <form @keyup.enter="resume" class="my-3 mt-6 divide-y">
              <div class="grid grid-cols-12 mb-2 gap-1">
                <Input label="CNPJ" name="CNPJ" mask="##.###.###/####-##" :autofocus="true"
                  :validation="formValidation.CNPJ" @change="changeField" class="col-span-12" />
                <Input label="Número do Protocolo" name="Protocol" :validation="formValidation.Protocol"
                  @change="changeField" class="col-span-12" />
              </div>
            </form>
            <PrimaryButton @click="resume" :btnClass="'next'" :icon="nextIcon" iconPosition="right" :loading="loading"
              label="Retomar Cadastro" class="w-full" />
            <div class="flex mt-3 justify-around">
              <div @click="$emit('new')" class="link new">Novo Cadastro</div>
              <div @click="recover()" class="link">Esqueci o Protocolo</div>
            </div>
            <div class="flex mt-2 pt-2 mb-6 border-t b-gray-200 justify-center" v-if="clientType == 'software-houses'">
              <div
                class="custom-primary-button cursor-pointer rounded-lg bg-indigo-500 text-white flex px-6 mt-2 py-2 justify-center">
                <div @click="tecnoaccount()" class="text-white">Inicializar com o Tecnoaccount</div>
                <img width="20" height="20" class="ml-3 my-auto w-5 h-6"
                  src="https://conta.tecnospeed.com.br/img/tecnoaccount_small.png" />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div id="recovery" :class="page == 'recovering' ? 'recovery-active' : ''"
      class="absolute w-full h-screen flex justify-center auth">
      <div class="custom-background-inverse absolute w-full h-1/2" />
      <div class="m-auto form">
        <embed width="200" height="50" src="/logo.svg" class="mx-auto" />
        <Card class="m-4 border-gray-200 shadow-md shadow-inner"
          :title="clientType == 'company' ? 'Insira os Dados do Estabelecimento' : 'Insira os Dados da Software House'"
          titlePosition="center">
          <form @keyup.enter="recovery" class="my-3 mt-6 divide-y">
            <div class="grid grid-cols-12 mb-2 gap-1">
              <Input label="CNPJ" name="RecoverCNPJ" mask="##.###.###/####-##" :autofocus="true"
                :validation="formValidation.RecoverCNPJ" @change="changeField" class="col-span-12" />
            </div>
          </form>
          <div class="block sm:flex justify-between">
            <Button @click="authenticate" :icon="backIcon" iconPosition="left" label="Voltar"
              class="w-full sm:w-min mb-3 sm:mb-none hover:bg-gray-300" />
            <PrimaryButton @click="recovery" :icon="nextIcon" iconPosition="right" :loading="loading"
              label="Enviar E-mail" class="ml-none sm:ml-4 mb-3 sm:mb-none w-full" />
          </div>
          <div class="flex mt-5 mb-6 justify-around">
          </div>
        </Card>
      </div>
    </div>
    <div id="tecnoaccount" :class="page == 'tecnoaccount' ? 'tecnoaccount-active' : ''"
      class="absolute w-full h-screen flex justify-center auth">
      <div class="custom-background-inverse absolute w-full h-1/2" />
      <div class="m-auto form">
        <embed width="200" height="50" src="/logo.svg" class="mx-auto" />
        <Card class="m-4 border-gray-200 shadow-md shadow-inner" title="Inicializar com o Tecnoaccount"
          titlePosition="center">
          <div class="my-8">
            <div class="block justify-center">
              <div class="grid grid-cols-12 mb-2 gap-1">
                <Input label="E-mail do usuário" name="user" :autofocus="true" :validation="formValidation.user"
                  @change="changeField" class="col-span-12" />
                <Input label="Senha" name="password" :hidden="true" :validation="formValidation.password"
                  @change="changeField" class="col-span-12" />
                <Button @click="authenticate" :icon="backIcon" iconPosition="left" label="Voltar"
                  class="col-span-5 hover:bg-gray-300" />
                <PrimaryButton iconPosition="right" :loading="loading" label="Enviar" @click="integrate"
                  class="ml-3 col-span-7" />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../ui/Card.vue'
import Button from '../buttons/Button'
import PrimaryButton from '../buttons/PrimaryButton'
import { faPlus, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Input from '../input/Input'
import api from '@/services/api';
import { cnpj, email } from 'validacao-docs'

export default {
  name: 'Auth',
  props: ['data', 'clientType'],
  components: {
    Card,
    PrimaryButton,
    Button,
    Input
  },
  data() {
    return {
      page: 'authenticating',
      selectedValue: null,
      loading: false,
      plusIcon: faPlus,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      Protocol: null,
      CNPJ: null,
      RecoverCNPJ: null,
      user: null,
      password: null,
      Email: null,
      formValidation: {},
    }
  },
  methods: {
    changeField(index, value) {
      if (index == 'CNPJ') {
        this.CNPJ = value
      }
      if (index == 'Protocol') {
        this.Protocol = value
      }
      if (index == 'RecoverCNPJ') {
        this.RecoverCNPJ = value
      }
      if (index == 'user') {
        this.user = value
      }
      if (index == 'password') {
        this.password = value
      }

      this.validate(index)
    },

    async authenticate() {
      this.page = 'authenticating'
      this.formValidation = {}
    },

    async tecnoaccount() {
      this.page = 'tecnoaccount'
      this.formValidation = {}
    },

    async recover() {
      this.page = 'recovering'
      this.formValidation = {}
    },

    validate(index) {
      if (index == 'CNPJ') {
        if (!cnpj.isValidMask(this.CNPJ)) {
          this.formValidation.CNPJ = 'O formato do CNPJ não é válido.'
          return false
        }
        if (!cnpj.isValid(this.CNPJ.replaceAll('/', '').replaceAll('.', '').replaceAll('-', ''))) {
          this.formValidation.CNPJ = 'O CNPJ não é válido.'
          return false
        }
        this.formValidation.CNPJ = null
      }

      if (index == 'RecoverCNPJ') {
        if (!cnpj.isValidMask(this.RecoverCNPJ)) {
          this.formValidation.RecoverCNPJ = 'O formato do CNPJ não é válido.'
          return false
        }
        if (!cnpj.isValid(this.RecoverCNPJ.replaceAll('/', '').replaceAll('.', '').replaceAll('-', ''))) {
          this.formValidation.RecoverCNPJ = 'O CNPJ não é válido.'
          return false
        }
        this.formValidation.RecoverCNPJ = null
      }

      if (index == 'user') {
        if (!email.isValidMask(this.user)) {
          this.formValidation[index] = 'O e-mail não é válido.'
          return false
        }
        if (!this.user) {
          this.formValidation[index] = 'Insira um e-mail válido.'
          return false
        }
        this.formValidation[index] = null
        return true
      }

      if (index == 'password') {
        if (!this.password) {
          this.formValidation[index] = 'Insira a senha.'
          return false
        }
      }

    },

    async integrate() {
      this.formValidation = {}
      if (!this.user) {
        this.formValidation.user = 'Insira um e-mail válido.'
      }
      if (!this.password) {
        this.formValidation.password = 'Insira a senha.'
      }
      if (!this.formValidation.user && !this.formValidation.password) {
        await api.post('/tecnoaccount/authentication',
          { email: this.user, password: this.password },
        ).then((response) => {
          this.$emit('new', {
            user: this.user,
            password: this.password,
            cnpj: response.data.cnpj
          })
        }).catch(() => {
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: 'Houve um problema ao enviar os dados, verifique se estão corretos e tente novamente.',
            confirmButtonText: 'Ok'
          })
        })
      }
    },

    async resume() {
      this.loading = true

      if (!this.CNPJ) {
        this.formValidation.CNPJ = 'Informe um CNPJ.'
        if (!this.Protocol) {
          this.formValidation.Protocol = 'Informe o número de protocolo.'
        }
        this.loading = false
        return false
      } else if (!this.Protocol) {
        this.formValidation.Protocol = 'Informe o número de protocolo.'
        this.loading = false
        return false
      }

      let response = await api.post('/authentication/', {
        protocolo: this.Protocol,
        role: this.clientType == 'company' ? 'company' : 'software-house',
        cnpj: this.CNPJ.replaceAll('/', '').replaceAll('.', '').replaceAll('-', ''),
        preRegisterId: this.$route.params.hash
      }).catch(() => {
        this.$swal({
          title: "Erro!",
          icon: "error",
          text: 'Os dados informados são inválidos.',
          confirmButtonText: 'Ok'
        })
        this.loading = false
      })

      const token = response.data.access_token
      localStorage.setItem('token', token)

      response = await api.get('/' + this.clientType, { headers: { Authorization: `Bearer ${token}` } }).catch(() => {
        this.$swal({
          title: "Erro!",
          icon: "error",
          text: 'Algo deu errado no envio dos dados.',
          confirmButtonText: 'Ok'
        })
        this.loading = false
      })

      if(response.data.status === 'cadastro_concluido') {
        this.$swal({
            title: "Cadastro Finalizado!",
            icon: "info",
            allowOutsideClick: true,
            text: 'Cadastro finalizado, em caso de dúvidas entre em contato com a sua Software House para maiores esclarecimentos.',
            confirmButtonText: 'Ok'
          })
          this.loading = false
          return;
      }

      this.loading = false
      this.$emit('resume', { data: response.data, protocol: this.Protocol })
    },

    recovery() {
      this.loading = true

      if (!this.RecoverCNPJ) {
        this.formValidation.RecoverCNPJ = 'Informe um CNPJ.'
        this.loading = false
      }

      api.get('/' + this.clientType + '/recuperacao/' + this.RecoverCNPJ.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '')).then(response => {
        this.$swal({
          title: "Sucesso!",
          icon: "success",
          text: 'Foi enviado um e-mail para ' + response.data.email + ' contento o número do protocolo!',
          confirmButtonText: 'Voltar'
        }).then(data => {
          this.recovering = false
          return data
        })
      }).catch((error) => {
        if (error.response.status == 404) {
          this.$swal({
            title: "Erro!",
            icon: "success",
            text: 'CNPJ não encontrado.',
            confirmButtonText: 'Ok'
          })
        }
      })
      this.loading = false
    },

    back() {
      this.$emit('cancel')
    }
  }
}
</script>