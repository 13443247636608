<style>
.custom-code-input {
    outline: none;
    border: solid 1px #e6e6e6;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    height: 30px;
}

.custom-code-input:focus {
    border: solid 1px #5854F1;
}
</style>

<template>
    <div class="flex gap-2 items-center">
        <input maxlength="1" ref="one" @paste="paste" @focus="clean('one')" class="custom-code-input rounded-lg p-2 w-8"
            @keyup="next('two')" v-model="one" />
        <input maxlength="1" ref="two" @paste="paste" @focus="clean('two')" class="custom-code-input rounded-lg p-2 w-8"
            @keyup="next('three')" v-model="two" />
        <input maxlength="1" ref="three" @paste="paste" @focus="clean('three')"
            class="custom-code-input rounded-lg p-2 w-8" @keyup="next('four')" v-model="three" />
        <input maxlength="1" ref="four" @paste="paste" @focus="clean('four')"
            class="custom-code-input rounded-lg p-2 w-8" @keyup="next('five')" v-model="four" />
        <input maxlength="1" ref="five" @paste="paste" @focus="clean('five')"
            class="custom-code-input rounded-lg p-2 w-8" @keyup="next('six')" v-model="five" />
        <input maxlength="1" ref="six" @paste="paste" @focus="clean('six')" class="custom-code-input rounded-lg p-2 w-8"
            @keyup="send" v-model="six" />
    </div>
</template>

<script>
export default {
    name: 'CodeInput',
    props: ['name'],
    data() {
        return {
            one: null,
            two: null,
            three: null,
            four: null,
            five: null,
            six: null,
        }
    },
    methods: {
        next(next) {
            this.$refs[next].value = null
            this.$refs[next].focus()
        },

        clean(index) {
            this.$refs[index].value = null
        },

        send() {
            this.$emit('changed', this.name, this.one + this.two + this.three + this.four + this.five + this.six)
            this.one = null
            this.two = null
            this.three = null
            this.four = null
            this.five = null
            this.six = null
            this.$el.getElementsByTagName("input")[0].focus()
        },
        paste(event) {
            event.preventDefault();
            let clipboardData = (event.clipboardData || window.clipboardData).getData('text').trim()
            let dataFromPaste = clipboardData.split('');
            this.one = dataFromPaste[0] || "";
            this.two = dataFromPaste[1] || "";
            this.three = dataFromPaste[2] || "";
            this.four = dataFromPaste[3] || "";
            this.five = dataFromPaste[4] || "";
            this.six = dataFromPaste[5] || "";

            this.$refs.six.focus();
        }
    }
}
</script>